<template>
  <div :class="prefixCls">
    <!--  播放类型text  -->
    <div
      v-if="text"
      :class="[
        prefixCls + '__type',
        layerValue ? prefixCls + '__type_active' : ''
      ]"
    >
      <span>{{ text }}</span>
    </div>
    <!--  播放按钮  -->
    <div :class="prefixCls + '__play'" @click="onPlayer">
      <img
        src="../../assets/images/loading.gif"
        v-show="!loading"
        style="width: 20px;height: 20px"
        :alt="$t('haiPlayer.load')"
      />
      <img
        src="../../assets/images/player@2x.png"
        v-show="loading && !layerValue"
        :alt="$t('haiPlayer.play')"
      />
      <img
        src="../../assets/images/stop@2x.png"
        v-show="loading && layerValue"
        :alt="$t('haiPlayer.pause')"
      />
    </div>
    <!--  开始时间  -->
    <div :class="prefixCls + '__time'">{{ startTime | formatSeconds }}</div>
    <!--  波峰图  -->
    <div :class="prefixCls + '__bar'" ref="sliderBar">
      <div
        :class="prefixCls + '__line'"
        v-if="bar"
        @mousedown="onPointerDragStart"
      >
        <div :class="prefixCls + '__line_1'"></div>
        <div
          :class="prefixCls + '__line_2'"
          :style="{ width: currentProgress + '%' }"
        ></div>
        <div
          :class="prefixCls + '__line_ball'"
          :style="{ left: currentProgress - 1 + '%' }"
          @mousedown="onMousedown"
        ></div>
      </div>
      <div
        :id="'wavesurfer' + _uid"
        class="wavesurfer-bar"
        v-show="!bar"
        :style="wavesurferStyle"
      ></div>
      <div
        :id="'wavesurfer1' + _uid"
        class="wavesurfer-copy"
        v-show="!bar"
        :style="wavesurferStyle"
      ></div>
    </div>
    <!--  结束时间  -->
    <div :class="prefixCls + '__time'">{{ endTime | formatSeconds }}</div>
    <!--  pandend  -->
    <div :class="prefixCls + '__pend'"></div>
  </div>
</template>

<script>
import WaveSurfer from 'wavesurfer.js'
import { formatSeconds } from '@/util'

const prefixCls = 'hai-player'
export default {
  name: 'HaiPlayer',
  props: {
    config: {
      type: Object,
      default: () => ({})
    },
    bar: {
      type: Boolean,
      default: false
    },
    autoPlay: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      prefixCls,
      layerValue: false,
      randomId: '',
      loading: false,
      wavesurferReady: false,
      wavesurferReady1: false,
      wavesurfer: null,
      wavesurfer1: null,
      startTime: 0,
      endTime: 0,
      sliderWidth: '',
      startX: '',
      unit: 0,
      startPos: 0
    }
  },
  computed: {
    showWavesurferBar() {
      return this.wavesurferReady && this.wavesurferReady1
    },
    wavesurferStyle() {
      return this.bar || !this.config.copy
        ? {}
        : {
            opacity: this.showWavesurferBar ? 1 : 0
          }
    },
    currentProgress() {
      return (this.startTime / this.endTime) * 100
    }
  },
  mounted() {
    this.$nextTick(this.initData)
  },

  methods: {
    onMousedown(event) {
      if (!this.loading) return
      event.preventDefault()

      window.addEventListener('mousemove', this.onPointerDrag)
      window.addEventListener('mouseup', this.onPointerDragEnd)
    },
    onPointerDragStart(event) {
      this.startX = this.getPointerX(event)
      let diff = this.startX - this.startPos
      this.startTime = this.unit * diff
      if (this.layerValue) {
        this.wavesurfer.play(this.startTime)
      }
    },
    onPointerDrag(event) {
      this.currentX = this.getPointerX(event)
      let diff = this.currentX - this.startPos
      this.wavesurfer.pause()
      if (diff < 0) {
        diff = 0
      } else if (diff > this.sliderWidth) {
        diff = this.sliderWidth
      }
      this.startTime = this.unit * diff
    },
    onPointerDragEnd() {
      if (this.layerValue) {
        this.wavesurfer.play(this.startTime)
      }
      window.removeEventListener('mousemove', this.onPointerDrag)
      window.removeEventListener('mouseup', this.onPointerDragEnd)
    },
    getPointerX(e) {
      return e.type.indexOf('touch') !== -1 ? e.touches[0].clientX : e.clientX
    },
    onPlayer() {
      if (!this.loading) return
      this.layerValue = !this.layerValue
      if (parseInt(this.startTime) === parseInt(this.endTime)) {
        this.startTime = 0
      }
      if (this.layerValue) {
        this.wavesurfer.play(this.startTime)
        this.wavesurfer1 && this.wavesurfer1.play(this.startTime)
      } else {
        this.wavesurfer.pause()
        this.wavesurfer1 && this.wavesurfer1.pause()
      }
    },
    initData() {
      this.wavesurfer = WaveSurfer.create({
        container: document.querySelector(`#wavesurfer${this._uid}`),
        waveColor: this.config.waveformBg1 || '#000',
        height: this.config.copy ? 40 : 60,
        barHeight: 1,
        hideScrollbar: true,
        progressColor: this.config.waveformBg2 || '#fff'
      })
      if (this.config.copy) {
        this.wavesurfer1 = WaveSurfer.create({
          container: document.querySelector(`#wavesurfer1${this._uid}`),
          waveColor: '#244f62',
          height: 60,
          barHeight: 1,
          cursorColor: 'rgba(0,0,0,0)',
          hideScrollbar: true,
          progressColor: this.config.waveformBg2 || '#fff'
        })
      }
      if (this.url) {
        this.wavesurfer.load(this.url)
      }

      if (this.wavesurfer1) {
        this.wavesurfer1.load(this.url)
        this.wavesurfer1.setVolume(0)
        this.wavesurfer1.on('ready', () => {
          this.wavesurferReady1 = true
        })
      }
      this.wavesurfer.on('ready', () => {
        this.wavesurferReady = true
        this.loading = true
        this.startTime = this.wavesurfer.getCurrentTime()
        this.endTime = this.wavesurfer.getDuration()
        this.sliderWidth = this.$refs['sliderBar'].clientWidth
        this.startPos = this.$refs['sliderBar'].getBoundingClientRect().left
        this.unit = this.endTime / this.sliderWidth
        if (this.autoPlay) {
          this.wavesurfer.play()
          this.layerValue = true
        }
      })
      this.wavesurfer.on('audioprocess', val => {
        this.startTime = val
        if (this.wavesurfer1) {
          this.wavesurfer1.play(this.startTime)
        }
        if (parseInt(this.startTime) === parseInt(this.endTime)) {
          this.layerValue = false
        }
      })
      this.wavesurfer.on('seek', val => {
        this.startTime = this.wavesurfer.getCurrentTime()
        if (this.wavesurfer1) {
          this.wavesurfer1.seekTo(val)
        }
      })
      this.wavesurfer.on('error', () => {})
    }
  },

  filters: {
    ...{ formatSeconds }
  },

  watch: {
    url: {
      handler(val) {
        if (val) {
          this.wavesurfer && this.wavesurfer.load(val)
        }
      },
      immediate: true
    }
  },
  beforeDestroy() {
    this.wavesurfer && this.wavesurfer.pause()
  }
}
</script>

<style lang="scss">
$prefixCls: 'hai-player';
.#{$prefixCls} {
  display: flex;
  align-items: center;
  margin: 0 20px;
  &__type {
    flex-shrink: 0;
    width: 160px;
    transition: all 0.3s;
    display: flex;
    justify-content: flex-start;
    span {
      padding: 8px 24px;
      border-radius: 10px;
      border: 1px #fff solid;
      font-size: 24px;
      color: #fff;
      display: inline-block;
    }
  }
  &__play {
    flex-shrink: 0;
    margin-right: 70px;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }
  &__time {
    width: 60px;
    flex-shrink: 0;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.65);
  }
  &__bar {
    width: 100%;
    margin: 0 30px;
    position: relative;
    .wavesurfer-bar {
      position: relative;
      z-index: 10;
    }
    .wavesurfer-copy {
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      z-index: 8;
    }
  }
  &__line {
    position: relative;
    padding: 10px 0;
    &_1 {
      height: 1px;
      width: 100%;
      background: rgba(255, 255, 255, 0.5);
    }
    &_2 {
      width: 0%;
      height: 1px;
      background: #fff;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    &_ball {
      cursor: pointer;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: #fff;
      position: absolute;
      left: -1%;
      top: 50%;
      transform: translateY(-50%) scale(0.7);
    }
  }
}
</style>
